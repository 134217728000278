export const environment = {
  production: true,
  appVersion: "v717demo1",
  USERDATA_KEY: "authf649fc9a5f55",
  isMockEnabled: true,
  //apiUrl: "http://workflowbackend/api",
  //qformUrl: "http://backend",

  // baseUrl: "http://localhost:4201",
  // apiUrl: "http://localhost/api",
  // qformUrl: "http://localhost:82",
  // qformFrontEndUrl: "http://localhost:4204",
  // INTEGRATION_HUB_FRONTEND_URL: "https://localhost:4200",
  // INTEGRATION_HUB_BACKEND_URL: "http://localhost:9000",

  baseUrl: "https://demoflow.qforms.co",
  apiUrl: "https://demoflowapi.qforms.co/api",
  qformUrl: "https://demoapi.qforms.co",
  qformFrontEndUrl: "https://demo.qforms.co",
  INTEGRATION_HUB_FRONTEND_URL: "https://demoihub.qforms.co",
  INTEGRATION_HUB_BACKEND_URL: "https://demoihubapi.qforms.co",

  servicenow_instanceid: "dev114543",
  servicenow_tablename: "u_mudasir_table_label",
};
export class Config {
  static DATE_FMT = "dd-MM-yyyy";
  static DATE_TIME_FMT = `${Config.DATE_FMT} hh:mma`;
  static secretKey = "drQ8pzjLMqEKZyAhyLqXlxZdyQTsEoiq";
}
